import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../../components/Icon";
import Dropdowncheckbox from "../../../components/Dropdowncheckbox";
import Checkbox from "../../../components/Checkbox";
import { useNavigate } from "react-router-dom";
const Panel = ({
  cameras,
  handleCapture,
  handleRecording,
  activeRecordings,
  onUpload,
  markAsPacked,
  currentOrder,
}) => {
  const [cameraOptions, setCameraOptions] = useState(() => {
    const savedOptions = localStorage.getItem("cameraOptions");
    if (savedOptions) {
      return JSON.parse(savedOptions);
    } else if (cameras.length === 1) {
      return {
        [cameras[0].deviceId]: { photo: true, video: true },
      };
    } else {
      return {};
    }
  });

  const [isVideoSelected, setIsVideoSelected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (cameras.length === 1 && Object.keys(cameraOptions).length === 0) {
      setCameraOptions({
        [cameras[0].deviceId]: { photo: true, video: true },
      });
    }

    const hasVideoSelected = Object.values(cameraOptions).some(
      (options) => options?.video
    );
    setIsVideoSelected(hasVideoSelected);
    localStorage.setItem("cameraOptions", JSON.stringify(cameraOptions));
  }, [cameras, cameraOptions]);

  const handleOptionChange = (cameraId, option) => {
    setCameraOptions((prevOptions) => ({
      ...prevOptions,
      [cameraId]: {
        ...prevOptions[cameraId],
        [option]: !prevOptions[cameraId]?.[option],
      },
    }));
  };

  const isRecording = activeRecordings.size > 0;

  const handleManualCapture = () => {
    const selectedCameras = Object.entries(cameraOptions)
      .filter(([_, options]) => options.photo)
      .map(([cameraId]) => cameraId);
    handleCapture(selectedCameras);
  };

  const handleManualRecording = () => {
    if (isVideoSelected) {
      const selectedCameras = Object.entries(cameraOptions)
        .filter(([_, options]) => options.video)
        .map(([cameraId]) => cameraId);
      handleRecording(selectedCameras);
    }
  };
  const handleOk = async () => {
    try {
      navigate("/packingslip", {
        state: {
          ids: [currentOrder?.id],
        },
      });
    } catch (error) {
      console.error("Error navigating:", error);
    }
  };
  return (
    <div className={cn("panel", styles.panel)}>
      <div className={styles.info}></div>
      <div className={styles.btns}>
        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap gap-4">
            {cameras.map((camera) => (
              <div key={camera.id}>
                <Dropdowncheckbox
                  value={camera.name}
                  setValue={() => {}}
                  options={[
                    <div key="options">
                      <Checkbox
                        content="Photo"
                        value={
                          cameraOptions[camera.deviceId]?.photo ??
                          cameras.length === 1
                        }
                        onChange={() =>
                          handleOptionChange(camera.deviceId, "photo")
                        }
                      />
                      <Checkbox
                        content="Video"
                        value={
                          cameraOptions[camera.deviceId]?.video ??
                          cameras.length === 1
                        }
                        onChange={() =>
                          handleOptionChange(camera.deviceId, "video")
                        }
                      />
                    </div>,
                  ]}
                  className={styles.customDropdown}
                />
              </div>
            ))}
            <div>
              <div>
                <button
                  className={cn("button", styles.button)}
                  onClick={handleManualCapture}
                >
                  Capture
                </button>
                <button
                  className={cn("button", styles.button, {
                    [styles.recording]: isRecording,
                    [styles.disabled]: !isVideoSelected && !isRecording,
                  })}
                  onClick={handleManualRecording}
                  disabled={!isVideoSelected && !isRecording}
                >
                  {isRecording ? "Stop Recording" : "Record"}
                </button>
                <button
                  className={cn("button", styles.button)}
                  onClick={markAsPacked}
                >
                  Mark as packed
                </button>
                <button
                  className={cn("button", styles.button)}
                  onClick={handleOk}
                >
                Print Packing Slip
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Panel;
