import React from "react";
import cn from "classnames";
import styles from "./Create.module.sass";

import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";

import { useMenu } from "../../../../context/Roles/MenuContext";
import { create_tags_barcode } from "../../../../API/TagBarcode";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import Card from "../../../../components/Card";
import { useFormikContext } from "../../context/SettingsContext";

const CreateBarcode = ({}) => {
  const { formikRef } = useFormikContext();

  const { setSubActiveTab } = useMenu();
  const initialValues = {
    tag_name: "",
    barcode: "",
  };

  const validationSchema = Yup.object({
    barcode: Yup.string()
      .matches(
        /^[a-zA-Z0-9]{1,3}$/,
        "Name must be up to 3 alphanumeric characters"
      )
      .required("Barcode is required"),
    tag_name: Yup.string().required("Tag name is required"),
  });

  const handleSave = async (
    values,
    { setTouched, setErrors, validateForm }
  ) => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          tag_name: true,
          cameraSettings: true,
        });
        setErrors(errors);
        return;
      }

      const formData = {
        tag_name: values.tag_name,
        barcode: values.barcode,
      };

      const res = await create_tags_barcode(formData);

      if (res.status === 201) {
        setSubActiveTab(1);

        message.success(res.data.message);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.errors;
        message.error(Object.values(errors[0])[0]);
      } else if (error.response.status === 500) {
        message.error(error.response.data.err);
      }
    }
  };

  const handleBack = () => {
    setSubActiveTab(1);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSave}
      innerRef={formikRef}
    >
      {({}) => (
        <Form>
          <Card
            className={cn(styles.card)}
            title="New Action Barcode for Tags"
            classTitle="title-red"
            head={
              <div className={styles.flex_box}>
                <button className={cn("button-stroke")} onClick={handleBack}>
                  <Icon name="arrow-left" size="24" />
                  <span>Back</span>
                </button>
              </div>
            }
          >
            <div className={styles.description}>
              <div className={styles.txt_container}>
                <Field name="barcode">
                  {({ field, meta }) => (
                    <TextInput
                      {...field}
                      label="Enter Barcode"
                      tooltip="Maximum 50 characters."
                      className={cn(styles.field)}
                      error={meta.touched && meta.error ? meta.error : ""}
                      required
                    />
                  )}
                </Field>
              </div>
              <div className={styles.txt_container}>
                <Field name="tag_name">
                  {({ field, meta }) => (
                    <TextInput
                      {...field}
                      label="Enter Tag"
                      tooltip="Maximum 50 characters."
                      className={cn(styles.field)}
                      error={meta.touched && meta.error ? meta.error : ""}
                      required
                    />
                  )}
                </Field>
              </div>
            </div>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateBarcode;
