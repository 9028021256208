import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import { PermissionsProvider } from "./context/Permissions/PermissionsContext";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";
import CustomerList from "./screens/CustomerList";
import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Earning from "./screens/Earning";
import Refunds from "./screens/Refunds";
import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import LandingPageIndex from "./LandingPage/LandingPageIndex";
import AllOrders from "./screens/Orders/AllOrders/AllOrders";
import ProtectedRoute from "./features/auth/ProtectedRoute";
import PublicRoute from "./features/auth/PublicRoute";
import AccessControlRoute from "./HOC/AccessRoute";
import MultiAccessRoute from "./HOC/MultiAccessRoute";
import Staffsindex from "./screens/Staff/Staffsindex";
// import ScanOredrs from "./screens/ScanOrders/ScanOrderIndex";
import AddStaffMember from "./screens/Staff/AddStaffMember/AddStaffMember";
import { MenuProvider } from "./context/Roles/MenuContext";
import { ProductProvider } from "./context/Product/ProductContext";
import GeneratePickListByOrderPrintView from "./screens/Orders/AllOrders/Filters/GeneratePickListByOrderPrintView";
import GeneratePickListByProductPrintView from "./screens/Orders/AllOrders/Filters/GeneratePickListByProductPrintView";
import { FormikProvider } from "./screens/Settings/context/SettingsContext";
import OrderDettailsIndex from "./screens/Orders/OrderDetails/OrderDettailsIndex";
import ScanningOrders from "./screens/ScanningOrders/Index";
import UpdateInventory from "./screens/Inventory/UpdateInventory/UpdateInventory";
import InventoryLogs from "./screens/Inventory/InventoryLogs/InventoryLogs";
import Details from "./screens/Inventory/InventoryLogs/InventoryLogsDetails/Details";
import Unauthorized from "./screens/Unauthorized/Unauthorized";
import Sync from "./screens/Sync";
import Privacy from "./screens/Privacy";
import Welcome from "./screens/Welcome";
import PackingSlip from "./components/Packingslip/PackingSlip";
function App() {
  return (
    <Routes>
      <Route index path="/*" element={<LandingPageIndex />} />
      <Route index path="/privacy" element={<Privacy />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/sync" element={<Sync />} />
      <Route element={<ProtectedRoute />}>
        <Route element={<PermissionsProvider />}>
          <Route
            element={
              <AccessControlRoute
                moduleCode="diMGeD"
                requiredPrivilege="91VsM3"
              />
            }
          >
            <Route
              path="/orders"
              element={
                <Page>
                  <AllOrders />
                </Page>
              }
            />

            <Route
              path="/order-details/:id"
              element={
                <Page>
                  <OrderDettailsIndex />
                </Page>
              }
            />
          </Route>
          <Route
            path="/generatePickList"
            element={<GeneratePickListByOrderPrintView />}
          ></Route>
          <Route
            path="/generatePickListByProduct"
            element={<GeneratePickListByProductPrintView />}
          ></Route>
          <Route path="/packingslip" element={<PackingSlip />}></Route>
          <Route
            element={
              <AccessControlRoute
                moduleCode="eV79MY"
                requiredPrivilege="xPGbZk"
              />
            }
          >
            <Route
              path="/staffs"
              element={
                <Page title="Staff list">
                  <Staffsindex />
                </Page>
              }
            />
          </Route>

          <Route
            element={
              <AccessControlRoute
                moduleCode="eV79MY"
                requiredPrivilege="9WgfDT"
              />
            }
          >
            <Route
              path="/staff/add"
              element={
                <Page>
                  <AddStaffMember />
                </Page>
              }
            />
          </Route>

          <Route
            element={
              <AccessControlRoute
                moduleCode="eV79MY"
                requiredPrivilege="xPGbZk"
              />
            }
          >
            <Route
              path="/staff/edit/:id"
              element={
                <Page title="Add Member">
                  <AddStaffMember />
                </Page>
              }
            />
          </Route>
          <Route
            element={
              <AccessControlRoute
                moduleCode="Lzk3eg"
                requiredPrivilege="kXdmje"
              />
            }
          >
            <Route
              path="/updateinventory"
              element={
                <Page title="Update Inventory">
                  <UpdateInventory />
                </Page>
              }
            />
          </Route>
          <Route
            element={
              <AccessControlRoute
                moduleCode="Lzk3eg"
                requiredPrivilege="kXdmje"
              />
            }
          >
            <Route
              path="/inventorylogs"
              element={
                <Page title="Inventory Logs">
                  <InventoryLogs />
                </Page>
              }
            />
          </Route>
          <Route
            element={
              <AccessControlRoute
                moduleCode="Lzk3eg"
                requiredPrivilege="kXdmje"
              />
            }
          >
            <Route
              path="/inventorylog/:id"
              element={
                <Page title="Inventory Logs">
                  <Details />
                </Page>
              }
            />
          </Route>
          <Route
            element={
              <MultiAccessRoute
                moduleCodes={["gsZHRE", "FvrVqN", "BjQPiy","k5WoWh","18q9ha","VBaqtS","2sxE9L"]}
                requiredPrivileges={["nnlrNJ", "nJLdKh", "OZnKi4","kI6pTK","4Yy3A1","HRkhhh","OFUpm7"]}
              />
            }
          >
            <Route
              path="/settings"
              element={
                <Page title="Settings">
                  <MenuProvider>
                    {" "}
                    <FormikProvider>
                      <Settings />
                    </FormikProvider>
                  </MenuProvider>
                </Page>
              }
            />
          </Route>
          <Route
          path="products/add"
          element={
            <Page title="New product">
              <ProductProvider>
                <NewProduct />
              </ProductProvider>
            </Page>
          }
        />
          <Route
            path="notification"
            element={
              <Page title="Notification">
                <Notification />
              </Page>
            }
          />
          {/* <Route
          path="/scanorder"
          element={
            <Page title="Scan Order">
              <ScanOredrs />
            </Page>
          }
        /> */}
          <Route
            element={
              <AccessControlRoute
                moduleCode="8FZN9w"
                requiredPrivilege="a2YwMA"
              />
            }
          >
            <Route
              path="/scan-order"
              element={
                <Page title="Scan Order">
                  <ScanningOrders />
                </Page>
              }
            />
          </Route>
          {/* <Route
          path="products/dashboard"
          element={
            <Page title="Products dashboard">
              <ProductsDashboard />
            </Page>
          }
        />
       
        <Route
          path="products/drafts"
          element={
            <Page title="Drafts">
              <Drafts />
            </Page>
          }
        />
        <Route
          path="products/released"
          element={
            <Page title="Released">
              <Released />
            </Page>
          }
        />
        <Route
          path="products/comments"
          element={
            <Page title="Comments">
              <Comments />
            </Page>
          }
        />
        <Route
          path="products/scheduled"
          element={
            <Page title="Scheduled">
              <Scheduled />
            </Page>
          }
        />
        <Route
          path="customers/overview"
          element={
            <Page title="Customers">
              <Customers />
            </Page>
          }
        />
        <Route
          path="customers/customer-list"
          element={
            <Page title="Customer list">
              <CustomerList />
            </Page>
          }
        />
        <Route
          path="shop"
          element={
            <Page wide>
              <Shop />
            </Page>
          }
        />
        <Route
          path="income/earning"
          element={
            <Page title="Earning">
              <Earning />
            </Page>
          }
        />
        <Route
          path="income/refunds"
          element={
            <Page title="Refunds">
              <Refunds />
            </Page>
          }
        />
        <Route
          path="income/payouts"
          element={
            <Page title="Payouts">
              <Payouts />
            </Page>
          }
        />
        <Route
          path="income/statements"
          element={
            <Page title="Statements">
              <Statements />
            </Page>
          }
        />
        <Route
          path="promote"
          element={
            <Page title="Promote">
              <Promote />
            </Page>
          }
        />
        <Route
          path="notification"
          element={
            <Page title="Notification">
              <Notification />
            </Page>
          }
        />

        <Route
          path="upgrade-to-pro"
          element={
            <Page title="Upgrade to Pro">
              <UpgradeToPro />
            </Page>
          }
        />
        <Route
          path="message-center"
          element={
            <Page title="Message center">
              <MessageCenter />
            </Page>
          }
        />
        <Route
          path="explore-creators"
          element={
            <Page title="Explore creators">
              <ExploreCreators />
            </Page>
          }
        />
        <Route
          path="affiliate-center"
          element={
            <Page title="Affiliate center">
              <AffiliateCenter />
            </Page>
          }
        /> */}
        </Route>
      </Route>

      <Route element={<PublicRoute />}>
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-in" element={<SignIn />} />
        <Route path="welcome" element={<Welcome />} />

        {/* <Route path="pagelist" element={<PageList />} /> */}
      </Route>
    </Routes>
  );
}

export default App;
