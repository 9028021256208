import React from "react";
import cn from "classnames";
import styles from "../Released.module.sass";
import Icon from "../../../../components/Icon";
import { Tooltip, message } from "antd";
import { EXPORT_ORDERS } from "../../../../API/Orders"; // Import the API function

function Export({
  checkedItems,
  risk,
  returnStatus,
  tags,
  notTaggedWith,
  appName,
  channelName,
  deliveryMethod,
  returrn,
  deliveryStatus,
  financial,
  fulfillmentStatus,
  valueFilterByDate,
  valueFilterByToDate,
  valueFilterByFromDate,
  page,
  totalItems,
  search,
  selectedEmployee,
  setPage,
  setTotalItems,
  itemsPerPage,
  activeButton,
  setActiveButton,
  duration,
}) {
  const generateQueryParam = (key, value) => (value ? `${key}=${value}` : "");

  const handleExport = async () => {
    try {
      const queryParams = [
        { key: "financialStatus", value: financial },
        { key: "fulfillmentStatus", value: fulfillmentStatus },
        { key: "orderNumber", value: search },
        { key: "fromDate", value: valueFilterByFromDate },
        { key: "toDate", value: valueFilterByToDate },
        // { key: "page", value: page },
        // { key: "pageSize", value: itemsPerPage },
        { key: "assignTo", value: selectedEmployee },
        { key: "riskLevel", value: risk },
        { key: "returnStatus", value: returnStatus },
        { key: "deliveryStatus", value: deliveryStatus },
        { key: "deliveryMethod", value: deliveryMethod },
        { key: "channel", value: channelName },
        { key: "app", value: appName },
        { key: "duration", value: duration },
      ];
      const queryString = queryParams
        .map((param) => generateQueryParam(param.key, param.value))
        .filter(Boolean)
        .join("&");

      const payload = {};

      if (tags && tags.length > 0) {
        payload.taggedWith = Array.isArray(tags) ? tags.join(",") : tags;
      }

      if (notTaggedWith && notTaggedWith.length > 0) {
        payload.notTaggedWith = Array.isArray(notTaggedWith)
          ? notTaggedWith.join(",")
          : notTaggedWith;
      }

      const selectedIds = Object.keys(checkedItems)
        .filter((key) => checkedItems[key])
        .map(Number);

      if (selectedIds.length > 0) {
        payload.ids = selectedIds;
      }

      const response = await EXPORT_ORDERS(queryString, payload);
      const downloadUrl = URL.createObjectURL(response);
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute("download", "orders.xlsx");
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      message.error("Error exporting orders:", error);
    }
  };

  const handleClick = () => {
    setActiveButton("export");
    handleExport();
  };

  return (
    <>
      <Tooltip title="Export">
        <div style={{ justifyContent: "end" }}>
          <div className="relative">
            <button
              className={cn(styles.link, {
                [styles.active]: activeButton === "export",
              })}
              onClick={handleClick}
            >
              <Icon name="export" size="24" />
            </button>
          </div>
        </div>
      </Tooltip>
    </>
  );
}

export default Export;
